/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import Header from "../Header"
import Footer from "../Footer"
import theme from "../../utils/theme"

const FlexContainerVert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  main {
    flex: 1;
  }
`

const RenderedLayout = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <FlexContainerVert>
      <Header siteTitle={site.siteMetadata.title} />
      <main>{children}</main>
      <Footer copyright={site.siteMetadata.copyright} />
    </FlexContainerVert>
  )
}

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <RenderedLayout children={children} />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
