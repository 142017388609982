import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Link } from "gatsby"
import { calculateRem } from "../../utils/javascript-styles"

import Logo from "../../images/logo.svg"

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
})

const propTypes = {
  yAxis: PropTypes.number,
  navMenu: PropTypes.arrayOf(navArray).isRequired,
  controlAfterAnimate: PropTypes.func.isRequired,
  activeLink: PropTypes.string.isRequired,
}

const NavBar = styled.div`
  position: ${props => (props.yAxis > 30 ? "fixed" : `relative`)};
  width: 100%;
  background-color: ${props => props.theme.black};
  color: white;
  padding: ${calculateRem(10)};
  transition: all 200ms;
  top: 0;
  z-index: 9999;
`

const StyledLogo = styled.img`
  margin: 1rem;
  width: ${calculateRem(200)};
  display: block;
`

const Menu = styled.div`
  display: flex;
  justify-content: space-around;
`
const MenuItem = styled(Link)`
  color: ${props => props.theme.gallery};
  font-size: ${calculateRem(16)};
  margin-bottom: 0;
  padding: ${calculateRem(12)} ${calculateRem(22)};
  text-decoration: none;
  transition: all 0.25s;
  &.active {
    color: white;
    -webkit-text-stroke: 0 white;
    text-shadow: 0 0 0 transparent;
    border-bottom: 3px solid ${props => props.theme.sushi};
    cursor: default;
  }
  &:not(.active):hover {
    transform: translateY(-5px);
  }
`

const Navigation = props => {
  const { navMenu, yAxis } = props
  return (
    <NavBar yAxis={yAxis}>
      <Row>
        <Col md={3}>
          <Link to="/">
            <StyledLogo
              src={Logo}
              alt="Snow Kings logo"
              yAxis={yAxis > 30 ? 0.9 : 1}
            />
          </Link>
        </Col>
        <Col md={9}>
          <Menu>
            {navMenu.map(item => (
              <MenuItem
                activeClassName="active"
                className={item.customClass}
                to={item.route}
                key={item.id}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Col>
      </Row>
    </NavBar>
  )
}
Navigation.propTypes = propTypes

export default Navigation
