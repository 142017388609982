import React from "react"
import styled from "styled-components"
import NavContainer from "./NavContainer"

const StyledHeaderContainer = styled.header`
  background-color: transparent;
  display: block;
`

const Header = () => {
  return (
    <StyledHeaderContainer>
      <NavContainer />
    </StyledHeaderContainer>
  )
}

export default Header
