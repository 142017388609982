import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const StyledHR = styled.hr`
  background-color: transparent;
  height: ${props => calculateRem(props.height)};
  margin: 0;
`

const Whitespace = ({ height }) => {
  return <StyledHR height={height} />
}

Whitespace.defaultProps = {
  height: 32,
}
Whitespace.propTypes = {
  height: PropTypes.number,
}

export default Whitespace
