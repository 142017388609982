import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import Header from "../Header"
import Footer from "../Footer"
import theme from "../../utils/theme"

const FlexContainerVert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  main {
    flex: 1;
  }
`
const StyledMagLayout = styled.div`
  padding: 10rem 5rem 5rem 5rem;
  background-color: ${props => props.theme.black};
`

const RenderedLayout = ({ children }) => {
  return (
    <FlexContainerVert>
      <Header />
      <StyledMagLayout>{children}</StyledMagLayout>
      <Footer />
    </FlexContainerVert>
  )
}

const LayoutLogin = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <RenderedLayout>{children}</RenderedLayout>
    </ThemeProvider>
  )
}

LayoutLogin.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLogin
