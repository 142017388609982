import React, { Component } from "react"

import MediaQuery from "react-responsive"
import cuid from "cuid"
import Navigation from "./Navigation"
import MobileNavigation from "./MobileNavigation"

class NavContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yAxis: null,
      navMenu: [
        {
          id: cuid(),
          route: "",
          title: "Home",
          anchor: "",
          isActive: false,
        },

        {
          id: cuid(),
          route: "the-joyner-hand",
          title: "The Joyner Hand",
          anchor: "the-joyner-hand",
          isActive: false,
        },

        {
          id: cuid(),
          route: "touchless-flatware",
          title: "Touchless Flatware",
          anchor: "touchless-flatware",
          isActive: false,
        },
        {
          id: cuid(),
          route: "fmd-grand-foundation",
          title: "FMD Grand Foundation",
          anchor: "fmd-grand-foundation",
          isActive: false,
        },
        {
          id: cuid(),
          route: "partnerships",
          title: "Partnerships",
          anchor: "partnerships",
          isActive: false,
        },
        {
          id: cuid(),
          route: "events-entertainment",
          title: "Events & Entertainment",
          anchor: "events-entertainment",
          isActive: false,
        },
        {
          id: cuid(),
          route: "contact",
          title: "Contact",
          anchor: "contact",
          isActive: false,
        },
      ],
      mobileMenuOpen: false,
      activeLink: "home",
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = () => {
    const yAxis = window.pageYOffset
    this.setState({
      yAxis,
    })
  }

  openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    })
  }

  render() {
    const { navMenu, activeLink, mobileMenuOpen, yAxis } = this.state
    return (
      <section>
        <MediaQuery query="(max-device-width: 1224px)">
          <MobileNavigation
            navMenu={navMenu}
            mobileMenuOpen={mobileMenuOpen}
            controlFunc={this.openMobileMenu}
          />
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1225px)">
          <Navigation yAxis={yAxis} navMenu={navMenu} activeLink={activeLink} />
        </MediaQuery>
      </section>
    )
  }
}

export default NavContainer
