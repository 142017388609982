import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

import { Row, Col } from "react-styled-flexboxgrid"
import { calculateRem } from "../../utils/javascript-styles"

import { IconMobileMenu, IconMobileMenuClose } from "../SvgIcons"

const navArray = PropTypes.shape({
  id: PropTypes.string,
  route: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
})

const propTypes = {
  navMenu: PropTypes.arrayOf(navArray).isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  controlFunc: PropTypes.func.isRequired,
  mobileNavControlFunc: PropTypes.func.isRequired,
}

const NavContainer = styled.nav`
  background: white;
  position: fixed;
  width: 100%;
`
const MobileNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 10px;
`
const MobileNavButton = styled.button`
  background: transparent;
  border: 0;
  width: ${calculateRem(50)};
  margin: 0;
  svg {
    display: block;
  }
`
const MobileCallButton = styled.a`
  border: 0;
  width: ${calculateRem(50)};
  margin: 0;
`

const MobileDDMenu = styled.div`
  background-color: white;
  margin: 0 auto;
`
const MobileMenuLink = styled(Link)`
  color: ${props => (props.active ? props.theme.oil : props.theme.oil)};
  display: block;
  font-family: "Montserrat";
  font-size: ${calculateRem(22)};
  font-weight: bold;
  margin-bottom: 0;
  padding: ${calculateRem(12)} ${calculateRem(22)};
  text-decoration: none;
  transition: background-color 0.25s;
  text-transform: uppercase;
  &.active {
    background-color: white;
    color: ${props => props.theme.coralRed};
  }
`

const MobileNav = props => {
  const {
    activeLink,
    controlAfterAnimate,
    controlFunc,
    mobileMenuOpen,
    navMenu,
  } = props
  return (
    <NavContainer>
      <Row>
        <Col xs={12}>
          <MobileNavContainer>
            <MobileNavButton onClick={controlFunc}>
              {!mobileMenuOpen ? <IconMobileMenu /> : <IconMobileMenuClose />}
            </MobileNavButton>
            <MobileCallButton href="tel:4045819687" />
          </MobileNavContainer>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {mobileMenuOpen && (
            <MobileDDMenu>
              {navMenu.map(item => (
                <MobileMenuLink
                  key={item.anchor}
                  to={item.anchor}
                  afterAnimate={() => controlAfterAnimate(item.anchor)}
                  className={activeLink === item.anchor ? "active" : ""}
                >
                  {item.title}
                </MobileMenuLink>
              ))}
            </MobileDDMenu>
          )}
        </Col>
      </Row>
    </NavContainer>
  )
}

MobileNav.propTypes = propTypes

export default MobileNav
