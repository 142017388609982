module.exports = {
  funGreen: "#016937",
  sushi: "#8cc640",
  oil: "#333",
  tundora: "#444",
  gray: "#919191",
  gallery: "#eaeaea",
  white: "#fff",
  black: "#000",
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
}
